function Contato() {
    return (
        <>
            <div className="min-h-[500px] flex items-center justify-center flex-col   " >
                <h2 className="text-secondary text-[32px]">Para qualquer dúvida ou informação, entre em contato por e-mail:</h2>
                <p className="text-heading text-2xl">thiagodiogenes@yahoo.com.br</p>
            </div>
        </>
    )
}
export default Contato