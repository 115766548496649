import AboutMe from "./AboutMe";
import Banner from "./Banner"
import Experiences from "./Experiences";



import Projects from "./Projects";



function Home() {
    return (
        <div>

            <Banner />
            <AboutMe />
            <Experiences />
            <Projects />

        </div>
    )
}
export default Home